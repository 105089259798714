
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Event } from '@toptoon-developers/global.toptoonplus.common.lib/dist/gtm/Gtm';
import { GlobalGtm } from '@toptoon-developers/global.toptoonplus.common.lib/dist/gtm/global';
import Head from 'next/head';
import type { NextPage } from 'next';
import SeoComponent from '@src/view/common/seo/SeoCompnent';
import TopcoPageHistory from '@src/lib/utils/topcoPageHistory';
import { TopcoPartner } from '@src/lib/partner';
import { UserSession } from '@src/lib/user/UserSession';
import dynamic from 'next/dynamic';
import { isRefererCheck } from '@src/lib/utils/utils';
import { openAdultCerti17Modal } from '@src/view/modal/adultCertification';
import useLogin from '@src/hooks/useLogin';
import { useRouter } from 'next/router';
import { useToasts } from 'react-toast-notifications';
import { openServerCheckBeforeNoticeModal } from '@src/view/modal/serverCheckBeforeNotice/ServerCheckBeforeNoticeModal';

const HomeComponent = dynamic<any>(
  () => import('@src/view/components/home/HomeComponent'),
  { ssr: false },
);

const Home: NextPage = (props: any) => {
  const { isReferer } = props;
  const router = useRouter();
  const { showJoinAndLoginModal, updateMature } = useLogin();
  const { addToast } = useToasts();

  // GTM - 메인페이지 열람
  useEffect(() => {
    GlobalGtm.getInstance().setter({
      event: Event.VIEW_MAIN,
      pCode: TopcoPartner.getter(),
      params: { user: { loginId: UserSession.getUserInfo().loginId ?? '' } },
    });
  }, [router]);

  useEffect(() => {
    if (!isReferer) {
      TopcoPageHistory.getInstance().clear();
      TopcoPageHistory.getInstance().setter({
        path: router.asPath,
        scroll: window.scrollY,
        fix: '',
      });
    }
  }, [isReferer]);

  useEffect(() => {
    const { action, targetUrl } = router.query;
    const { token } = UserSession.getUserInfo();

    if (action && action === 'login' && token.length === 0) {
      showJoinAndLoginModal(targetUrl ? `${targetUrl}` : '');
    } else if (action === 'login') {
      router.replace('/');
    } else if (token && action && action === 'mature') {
      openAdultCerti17Modal({
        onConfirm: () => {
          updateMature({
            token,
            mature: 1,
            auth: 1,
            callback: {
              onComplete: () => {
                router.replace(targetUrl ? `${targetUrl}` : '/');
              },
              onError: () => {},
            },
          });
        },
      });
    } else if (action && action === 'promoForbidden') {
      addToast(
        <div className="text-[.9rem]">
          <div>Please be patient.</div>
          <div>New promotional series are getting ready!</div>
        </div>,
      );

      router.replace('/');
    }
  }, [router.query]);

  return (
    <>
      <Head>
        <link rel="manifest" href="/favicon/manifest.json" />
      </Head>
      <SeoComponent.Main />
      <HomeComponent />
    </>
  );
};

 async function _getServerSideProps(context: any) {
  const isReferer: boolean = isRefererCheck(context);

  await TopcoPartner.setterSSR(context);

  return {
    props: {
      isReferer,
    },
  };
}

export default Home;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  