import React, { useCallback } from 'react';

import { CloseBtn } from '../../common/closeBtn';
import { ModalInstance } from '@src/lib/CustomModal/ModalContainer';
import cn from 'clsx';

const key = 'adultCertiModal';

/**
 * 기존 VerifyageModal
 * isForse17이 true 일때 보여주는 화면
 */
interface propTypes {
  onConfirm?: () => void;
}

export const openAdultCerti17Modal = (props: propTypes) => {
  ModalInstance.getInstance().push({
    key: key,
    className: 'verifyage-modal-con',
    shouldCloseOnOverlayClick: true,
    component: <AdultCerti17Modal onConfirm={props.onConfirm} />,
  });
};

export const closeAdultCerti17Modal = () => {
  ModalInstance.getInstance().delete(key);
};

const AdultCerti17Modal = (props: propTypes) => {
  const { onConfirm } = props;
  const onClick = useCallback((e: any) => {
    closeAdultCerti17Modal();
    onConfirm && onConfirm();
  }, []);

  return (
    <div className="max-w-[432px] bg-[#FCE6FC] max-h-[90vh] w-[88vw] z-[100] relative">
      <div>
        <CloseBtn
          imgPath="/images/alert/close/ico_close.png"
          className="w-[35px] h-[35px] top-[4px] right-[4px] absolute"
          onClick={() => {
            closeAdultCerti17Modal();
            // UserDefault.PreAuthModalAutoFlag.setResetFlag(); // 24시간 후 열리게 하는 flag
            // closePreAuthModal();
          }}
        />

        <div className="tracking-0.2 py-[15px] px-0">
          <div className="pt-[1rem] px-[10px] text-[#786A72] text-[.8rem] font-medium leading-[1.1rem] text-center">
            <img
              src="/images/alert/char_pre_auth.png"
              className="mt-0 mx-auto mb-[10px] h-[120px] rendering-auto"
              alt="ault18"
            />
            <p className="inline-block font-medium align-top">
              <span>This is just a peek!</span>
              <br />

              <span className="text-[0.65rem]">
                To see everything from head to toe, please verify your age
                first.
              </span>
              <br />
            </p>
            <div className="my-[10px] flex gap-x-[9px] justify-center items-center">
              {/* <img
                className={cn('inline-block bg-[#FCE6FC] w-[17px] h-[17px]')}
                src={'/images/alert/pre_auth_checkbox.png'}
                alt="pre_auth_checkbox"
              /> */}
              <span className="text-[#9B286D] text-[1rem] font-semibold">{`I'm over the age of 18`}</span>
            </div>

            <p>
              <span className="text-[0.725rem]">
                More than 400 explict contents are waiting ahead!
              </span>
            </p>
          </div>
        </div>
        <div className="text-center">
          <button
            className={cn(
              'text-white bg-[#9B286D] py-[15px] h-full w-full text-[0.9rem] tracking-0.2 cursor-pointer',
              'dark:text-[#575960] dark:border-[#575960]',
            )}
            onClick={onClick}
          >
            {'GET IN >'}
          </button>
        </div>
      </div>
    </div>
    // <div className="max-w-[400px] bg-white max-h-[90vh] w-[88vw] z-[100] relative">
    //   <div>
    //     <CloseBtn
    //       imgPath="/images/alert/close/ico_close.png"
    //       className="w-[35px] h-[35px] top-[4px] right-[4px] absolute"
    //       onClick={() => closeAdultCerti17Modal()}
    //     />

    //     <div className="tracking-0.2 py-[15px] px-0">
    //       <div className="pt-[1rem] px-[10px] text-666 text-[.8rem] font-normal leading-[1.1rem] text-center">
    //         <img
    //           src="/images/alert/char_mature_dark.png"
    //           className="mt-0 mx-auto mb-[10px] h-[110px]"
    //           alt="ault18"
    //         />
    //         <p>
    //           <span className="inline-block font-medium align-top text-primary-text dark:text-[#666]">
    //             Explicit contents ahead!
    //             <br />
    //             Viewer discretion is advised.
    //           </span>
    //           <br />
    //         </p>
    //         <p className="text-primary-text-point text-[17px] mt-[20px]">
    //           <span>300 FREE episodes are released now!</span>
    //         </p>
    //       </div>
    //     </div>
    //     <div className="text-center mb-[15px]">
    //       <button
    //         className={cn(
    //           'text-primary-text border-[#bbb] p-[5px_30px] h-full text-[1rem] tracking-0.2 cursor-pointer border-[2px] border-solid',
    //           'dark:text-[#575960] dark:border-[#575960]',
    //         )}
    //         onClick={onClick}
    //       >
    //         Got it
    //       </button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default AdultCerti17Modal;
